<template>
  <section class="eLearningJunior container">
    <div class="eLearningJunior__cardContainer">
      <ELearningImageCard
        v-for="(elementaryItem, index) in juniorFilterLists"
        :key="index"
        :item="elementaryItem"
        :type="'junior'"
      />

      <hr class="eLearningJunior__hr" />
    </div>
  </section>
</template>

<script>
import ELearningImageCard from '@/components/ELearning/ELearningImageCard.vue'

import { apiGetELearning } from '@/api/webAPI'

export default {
  components: {
    ELearningImageCard
  },
  data() {
    return {
      juniorLists: []
    }
  },
  async created() {
    const eLearningResponse = await apiGetELearning('junior')
    this.juniorLists = eLearningResponse.data.data

    // 給banner過濾年份用
    this.$store.commit('setELearning', this.juniorLists)
  },
  computed: {
    juniorFilterLists() {
      const filterYear = this.$store.state.eLearningYear
      if (!filterYear) return this.juniorLists
      return this.juniorLists.filter(item => item.year === filterYear)
    }
  }
}
</script>

<style lang="scss">
.eLearningJunior {
  padding: 4.9rem 0;
  overflow: hidden;

  &__cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -1.8rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 10.4rem 0 10rem 0;
  }
}

@media (max-width: 768px) {
  .eLearningJunior {
    padding: 4.9rem 2rem;
  }
}
</style>
